{
  "name": "solo-scores",
  "displayName": "Solo Scores",
  "version": "5.5.2",
  "description": "",
  "source": "src/index.html",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "scripts": {
    "dev": "parcel --port 3000",
    "build": "parcel build",
    "typescript": "tsc --noEmit --pretty"
  },
  "author": "Aled Jones",
  "license": "MIT",
  "staticFiles": {
    "staticPath": "public"
  },
  "devDependencies": {
    "@babel/core": "^7.21.4",
    "@parcel/packager-raw-url": "^2.8.3",
    "@parcel/service-worker": "^2.8.3",
    "@parcel/transformer-css": "^2.8.3",
    "@parcel/transformer-webmanifest": "^2.8.3",
    "@types/color": "^3.0.3",
    "@types/dom-screen-wake-lock": "^1.0.1",
    "@types/jest": "^29.5.0",
    "@types/node": "^18.15.11",
    "@types/offscreencanvas": "^2019.7.0",
    "@types/react": "^18.0.31",
    "@types/react-dom": "^18.0.11",
    "@types/shortid": "^0.0.29",
    "@types/uuid": "^9.0.1",
    "buffer": "^5.5.0",
    "parcel": "^2.8.3",
    "parcel-reporter-static-files-copy": "^1.5.0",
    "process": "^0.11.10",
    "typescript": "^5.0.3"
  },
  "dependencies": {
    "@dnd-kit/core": "^6.0.8",
    "@dnd-kit/sortable": "^7.0.2",
    "@mdi/js": "^7.2.96",
    "@mdi/react": "^1.6.1",
    "@supabase/supabase-js": "^2.13.1",
    "app-reset": "^1.0.2",
    "array-move": "^4.0.0",
    "big.js": "^6.2.1",
    "classnames": "^2.3.2",
    "color": "^4.2.3",
    "fuzzysort": "^2.0.4",
    "localforage": "^1.10.0",
    "pdfjs-dist": "^3.4.120",
    "pretty-bytes": "^6.1.0",
    "pullstate": "^1.25.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-hot-toast": "^2.4.0",
    "react-hotkeys-hook": "^4.3.8",
    "react-intersection-observer": "^9.4.3",
    "react-router-dom": "^6.10.0",
    "react-use": "^17.4.0",
    "styled-jsx": "^5.1.2",
    "swr": "^2.1.1",
    "uuid": "^9.0.0"
  }
}
